<script lang='ts'>
  import IconBookmarkSimple from "@/components/icons/phosphor/IconBookmarkSimple.svelte";
  import FakeButton from '@/components/ui/FakeButton.svelte'

  export let bookmarked = false,
    onClick: () => void
</script>
<FakeButton {onClick}>
  {#if bookmarked}
    <span class='bookmarksColor'><IconBookmarkSimple size='24' /></span>
  {:else}
    <IconBookmarkSimple size='24' weight='regular' />
  {/if}
</FakeButton>

<style lang='scss'>
  :global(.fake-button .bookmarksColor) {
    height: 2.4rem;
    color: var(--Green-Medium);
  }
</style>
