<script lang='ts'>
  import { _ } from '@/helpers/i18n'

  import IconLock from "@/components/icons/phosphor/IconLock.svelte";

  import { CategoryTopic } from '@/definitions/langoid'

  export let topic: CategoryTopic = {} as CategoryTopic
  export let fill = 'none'
</script>

<span title={$_('forum.requiredLevel') + ' ' + topic.read_level + '; ' + $_('forum.requiredAdminLevel') + ' ' + topic.admin_read_level} style='color: {fill}'>
  <IconLock size='13' weight='regular'/>
</span>
