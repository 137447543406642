<script lang='ts'>
  import { posphorIcons } from '@/helpers/icons'

  export let icon: keyof typeof posphorIcons,
    size = '24',
    weight: 'duotone' | 'light' | 'regular' | 'fill' = 'duotone'

</script>
{#if posphorIcons[icon]}
  <svelte:component this={posphorIcons[icon]} {size} {weight} />
{/if}
